import { Payment } from '@tezospayments/common';

import type { EncodedSerializedPayment } from '../../../models/payment';
import { errors } from '../errors';
import { PaymentProvider } from './paymentProvider';

export class SerializedPaymentBase64Provider implements PaymentProvider {
  isMatch(_encodedSerializedPayment: EncodedSerializedPayment): boolean {
    return true;
  }

  getPayment(encodedSerializedPayment: EncodedSerializedPayment): Payment | Promise<Payment> {
    const serializedPayment = encodedSerializedPayment.substring(2);
    const payment = Payment.deserialize(serializedPayment);

    if (!payment || Payment.validate(payment))
      throw new Error(errors.invalidPayment);

    return payment;
  }
}
